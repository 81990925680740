import { axios1 } from '../instance'

/*  设备管理 */

// 设备列表

/**
 * 设备导出
 * @param {object} data
 * @return {Promise}
 */
export const saveOrUpdate = data => {
  return axios1({
    method: 'POST',
    url: '/device/export',
    data: data,
    responseType: 'blob',
    headers: {
      'Content-Type': 'application/json'
    }
  })
  // return axios1.postByFormData('/device/export', data)
}

/**
 * 企业id查询 （企业列表）
 * @param {object} data
 * @param {number} data.customerName  公司名 （支持模糊查询）丰
 * @return {Promise}
 */
export const getEnterpriseList = data => {
  return axios1.postByJson('/customer/search', data)
}

/**
 * 获取下拉列表
 * @param {object} data
 * @param {string} data.entryVariable  必须字段， 要查询的条目 供应商
 * @param {string} data.pageSize   页大小
 * @param {string} data.pageNum   当前页
 * @return {Promise}
 */
export const getVendorList = data => {
  return axios1.postByJson('/dictionary/catalog/item/page', data)
}

/**
 * 设备新增
 * @param {object} data
 * @param {number} data.deviceCount  设备数量
 * @param {string} data.customerCode 客户编码(企业)
 * @param {string} data.customerName 客户名称
 * @param {number} data.deviceMainSub  是否主柜，1-主柜 2-副柜
 * @param {number} data.deviceOwner  设备所属 1-丰巢机 2-客户机 1
 * @param {string} data.deviceTemplateCode  设备模板编码
 * @param {string} data.manufactureCode  供应商编码
 * @param {string} data.manufactureName   供应商名称
 * @param {string} data.productCode  内部产品型号
 * @return {Promise}
 */
export const createEquipmentList = data => {
  return axios1.postByJson('/device/create', data)
}

/**
 * 查询设备列表
 * @param {object} data
 * @param {String} data.customerDeviceCodeArrStr  原设备编码
 * @param {String} data.deviceCodeArrStr  设备编码
 * @param {Array} data.lockerCodeArrStr  柜机编码
 * @param {string} data.productCodeLike   内部产品型号
 * @param {string} data.deviceTemplateCodeLike  模版id （设备模板编码）
 * @param {string} data.deviceOwner  设备所属 1-丰巢机 2-客户机
 * @param {string} data.deviceMainSub  是否主柜，1-主柜 2-副柜
 * @param {string} data.manufactureCode  供应商
 * @param {string} data.activeSysTmStart  激活开始时间
 * @param {string} data.activeSysTmEnd   激活结束
 * @param {string} data.currentActiveSysTmStart   最后一次时间（开始）
 * @param {string} data.currentActiveSysTmEnd  最后一次时间（结束）
 * @param {string} data.createSysTmStart  导入开始时间
 * @param {string} data.createSysTmEnd   导入结束时间
 * @param {number} data.status    状态，0未激活，1已激活 0
 * @param {number} data.pageNum  页码
 * @param {number} data.pageSize  页数
 * @return {Promise}
 */
export const getEquipmentList = data => {
  return axios1.postByJson('/device/search', data)
}

/**
 * 查询设备硬件产品(设备内部产品型号筛选)
 * @param {object} data
 * @param {string} data.deviceMainSub //主副柜
 * @param {string} data.pageNum  页码
 * @param {string} data.pageSize  页数
 * @return {Promise}
 */
export const queryHardware = data => {
  return axios1.postByJson('/device/hardware/product/search', data)
}

/**
 * 设备模板查询 (设备筛选可视化模板)
 * @param {object} data
 * @param {string} data.deviceMainSub //主副柜
 * @param {string} data.deviceTemplateLike 模糊查询
 * @param {string} data.pageNum  页码
 * @param {string} data.pageSize  页数
 * @return {Promise}
 */
export const searchTemp = data => {
  return axios1.postByJson('/device/template/search', data)
}

/**
 * 获取单个设备模板（可视化） （v1.1在做）
 * @param {object} data
 * @param {string} data.deviceTemplateCode  设备模板编码
 * @return {Promise}
 */
export const getTemplate = data => {
  return axios1.postByJson('/device/template/get', data)
}

/**
 * 查询柜机关联设备列表(查询柜机规格)
 * @param {object} data
 * @param {string} data.lockerCode  柜机编码
 * @return {Promise}
 */
export const searchListLocker = data => {
  return axios1.postByJson('/device/searchList4Locker', data)
}

/**
 * 设备删除
 * @param {object} data
 * @param {Array} data.deviceCodeList  设备编码列表
 * @return {Promise}
 */
export const dropTelist = data => {
  return axios1.postByJson('/device/drop', data)
}

/**
 * 设备编辑（单个）
 * @param {object} data
 * @param {string} data.customerCode 客户编码(企业)
 * @param {string} data.deviceCode  设备编码
 * @param {Array} data.deviceCodeList  设备编码列表
 * @param {string} data.deviceTemplateCode  设备模板编码
 * @param {string} data.productCode  内部产品型号
 * @return {Promise}
 */
export const singleEdit = data => {
  return axios1.postByJson('/device/edit', data)
}

/**
 * 批量设备编辑
 * @param {object} data
 * @param {string} data.customerCode 客户编码(企业)
 * @param {Array} data.deviceCodeList  设备编码列表
 * @param {string} data.deviceTemplateCode  设备模板编码
 * @param {string} data.productCode  内部产品型号
 * @return {Promise}
 */
export const batchEdit = data => {
  return axios1.postByJson('/device/batchEdit', data)
}

// 可视化模板

/**
 * 查询设备模板（可视化模板界面列表）
 * @param {object} data
 * @param {string} data.deviceTemplateName 模版名称
 * @param {string} data.deviceTemplateNameLike 模版名称 （模糊查询）
 * @param {string} data.deviceTemplateCode 模版id
 * @param {string} data.deviceTemplateCodeLike 模版id （模糊查询）
 * @param {string} data.pageNum  页码
 * @param {string} data.pageSize  页数
 * @return {Promise}
 */
export const searchWithStatic = data => {
  return axios1.postByJson('/device/template/searchWithStatic', data)
}

/**
 * 查询内部型号
 * @param {*} data
 * @returns
 */
export const getProductCodes = (data) => {
  return axios1.postByJson('/device/hardware/product/codes', data)
}

export const createTemplate = (data) => {
  return axios1.postByJson('/device/template/create', data)
}

export const getTemplateDetail = (id) => {
  return axios1.get(`/device/template/detail?deviceTemplateCode=${id}&language=zh-CN`)
}

export const checkTemplateLinked = (id) => {
  return axios1.get(`/device/template/linked/validate?deviceTemplateCode=${id}`)
}

export const editTemplate = (data) => {
  return axios1.postByJson('/device/template/update', data)
}

export const deleteTemplate = (data) => {
  return axios1.postByJson('/device/template/delete', data)
}